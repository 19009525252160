<template>
    <div class="discount-code-section" v-if="user && discountPointSetting && userDiscountPoints.amount != undefined && !discountPointSetting.isHidden">
        <h4>{{ $t('discountPoints.title') }}</h4>
        <h5>
            {{ $t('discountPoints.readMoreAboutDiscountPoints') }} <span>{{ $t('common.here') }}</span>
        </h5>
        <h1 class="points-count">{{ userDiscountPoints.amount.toFixed(0) }}</h1>
        <p class="points-description">
            {{ $t('discountPoints.theAmountOfPointsHaveExpiresAfter', { date: userDiscountPoints.expirationDate }) }}
        </p>
        <p class="points-description mb-16">
            {{ $t('discountPoints.maximumAllowedOrderPoints', { percentage: discountPointSetting.percentage }) }}
        </p>
        <CRButton @click="applyPoints" :disabled="!userDiscountPoints.amount || !!appliedDiscountPoints">
            {{ !appliedDiscountPoints ? $t('buttons.usePoints') : $t('buttons.pointsApplied') }}
        </CRButton>

        <CRButton v-if="appliedDiscountPoints" class="mt-10" @click="resetPoints" pattern="thin">
            {{ $t('buttons.resetPoints') }}
        </CRButton>
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import { DiscountPointSettingApi } from '@/api';
    import { mapActions, mapGetters } from 'vuex';
    import { getDiscount } from '@/helpers/CartHelper';

    export default {
        name: 'DiscountPointSection',
        components: { CRButton },
        props: {
            price: {
                type: String,
                default: '0',
            },
        },
        data() {
            return {
                discountPointSetting: null,
                discountCode: '',

                errors: {},
            };
        },
        computed: {
            ...mapGetters('discountPoint', ['userDiscountPoints', 'appliedDiscountPoints']),
            ...mapGetters('auth', ['user']),
        },
        methods: {
            ...mapActions('discountPoint', ['applyDiscountPoints']),

            applyPoints() {
                let discountWithPoints = getDiscount(this.price, this.discountPointSetting.percentage);

                let pointsToUse = discountWithPoints / this.discountPointSetting.amountToSpend;

                if (pointsToUse > this.userDiscountPoints.amount) {
                    pointsToUse = this.userDiscountPoints.amount;
                    discountWithPoints = (pointsToUse * this.discountPointSetting.amountToSpend).toFixed(2);
                }

                const appliedDiscountPoint = {
                    pointsToUse,
                    discountWithPoints,
                };

                this.applyDiscountPoints(appliedDiscountPoint);
            },

            resetPoints() {
                this.applyDiscountPoints(null);
            },

            async fetchDiscountPointSetting() {
                try {
                    const response = await DiscountPointSettingApi.get();
                    this.discountPointSetting = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },
        async mounted() {
            await this.fetchDiscountPointSetting();
        },
    };
</script>

<style lang="scss" scoped>
    .discount-code-section {
        h4 {
            margin-bottom: 8px;

            @include media($md) {
                font-size: $font-20;
            }
        }

        h5 {
            opacity: 0.7;
            margin-bottom: 20px;

            span {
                text-decoration: underline;
            }
        }

        .points-count {
            font-size: 80px;
            color: $primary;
            line-height: 100%;
        }

        .points-description {
            font-size: 14px;
            line-height: 18px;
        }
    }
</style>
