<template>
    <div class="discount-code-section" v-if="!hasUniqueMealKit">
        <h4>{{ $t('common.discountCode') }}</h4>
        <h5>
            {{ $t('cart.readAboutDiscountCode') }} <RouterLink
                :to="{ name: 'termsAndConditions', query: { section: 'discount' } }"><span>{{ $t('common.here')
                    }}</span>
            </RouterLink>
        </h5>
        <div class="tag__wrapper" v-if="discountCodes.length">
            <CRTag class="tag" v-for="discountCode in discountCodes" :key="discountCode.id" type="additional"
                delete-allowed svg-color="#00595A" @delete="removeDiscountCode(discountCode.id)">
                {{ `${discountCode.code} - ${(discountCode.amount == 0 ? '' : discountCode.amount +
                    getSign(discountCode.type))} ${discountCode.isFreeDelivery ? $t('common.freeDelivery') : ''}` }}
            </CRTag>
        </div>
        <CRInput class="input" v-model="discountCode" :label="$t('common.discountCode')"
            :placeholder="$t('placeholder.enterDiscountCode')" :errors="errors?.discountCode"
            @onChange="$filters.clearError(errors, 'discountCode')" />
        <CRButton @click="applyCode" :disabled="discountCodes.length === 1">
            {{ $t('buttons.addCode') }}
        </CRButton>
    </div>
</template>

<script>
import CRTag from '@/components/Common/Tags/CRTag.vue';
import CRInput from '@/components/Common/Inputs/CRInput.vue';
import CRButton from '@/components/Common/Buttons/CRButton.vue';
import { DiscountCodeApi } from '@/api';
import { mapGetters, mapActions } from 'vuex';
import { getSign } from '@/helpers/SignHelper';

export default {
    name: 'DiscountCodeSection',
    components: { CRInput, CRButton, CRTag },
    data() {
        return {
            getSign,
            discountCode: '',

            errors: {},
        };
    },
    computed: {
        ...mapGetters('cart', ['cart', 'discountCodes']),
        ...mapGetters('city', ['selectedCity']),
        ...mapGetters('orderInformation', ['buyerInformation']),
        hasUniqueMealKit() {
            return this.cart.some((item) => item.mealKit.isUnique);
        }
    },
    methods: {
        ...mapActions('cart', ['addDiscountCode', 'removeDiscountCode']),

        async applyCode() {
            if (this.discountCodes.length === 1) {
                this.errors.discountCode = [this.$t('errors.discountCodeAlreadyApplied')];
            } else {
                if (this.discountCode.length > 0) {
                    try {

                        const hasUniqueMealKit = this.cart.some((item) => item.mealKit.isUnique);

                        if (hasUniqueMealKit) {
                            this.errors.discountCode = [this.$t('errors.uniqueMealKitRequired')];
                            return; // Exit if no unique meal kit is found
                        }

                        const orders = this.cart.map((item) => {
                            return {
                                mealKitId: item.mealKit.id,
                                periodId: item.period.id,
                            };
                        });


                        const params = {
                            orders,
                            discountCode: this.discountCode,
                            city_id: this.buyerInformation !== null ? this.buyerInformation.cityData.id : this.selectedCity?.id,
                        };

                        const response = await DiscountCodeApi.checkIsCodeSuitable(params);
                        if (!this.checkIfObjectAddedInArray(response.data)) {
                            this.addDiscountCode(response.data);
                            this.discountCode = '';
                        } else {
                            this.errors.discountCode = [this.$t('errors.discountCodeAlreadyApplied')];
                        }
                    } catch (error) {
                        this.errors = error.errors;
                        console.log(error);
                    }
                }
            }
        },
        checkIfObjectAddedInArray(item) {
            return this.discountCodes.some((elem) => elem.id === item.id);
        },
    },
    created() {
        console.log(this.discountCodes);
    }
};
</script>

<style lang="scss" scoped>
.discount-code-section {
    h4 {
        margin-bottom: 8px;

        @include media($md) {
            font-size: $font-20;
        }
    }

    h5 {
        opacity: 0.7;
        margin-bottom: 20px;

        span {
            text-decoration: underline;
        }
    }

    .tag__wrapper {
        @include row-align-center;
        flex-wrap: wrap;

        margin-bottom: 30px;

        .tag {
            margin-right: 8px;
        }
    }

    .input {
        margin-bottom: 16px;
    }
}
</style>
